/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import styles from './chat-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import { ChatInput } from './chat-input';
import { CheckIcon } from '../../icons/check/check';
import chatEmpty from '../../assets/chat-empty.svg';

export const ChatModal = observer(() => {
  const {
    showModal,
    closeModal,
    chatStore,
    gameData,
    getTeamColorById,
  } = useAppStore();
  const {
    users,
    sendMessage,
    setSelectedChat,
    selectedChat,
    selectedChatName,
    messages,
    fetchMessages,
    readMessages,
    unconsumedMessages,
    isOwnLastMessage,
  } = chatStore;
  const isOpened = showModal === 'chat';
  const scrollRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchMessages();
  }, [selectedChat]);

  useEffect(() => {
    if (scrollRef.current && isOpened && selectedChat) {
      scrollRef.current.scrollToBottom();
    }
  }, [messages, isOpened, selectedChat]);

  useEffect(() => {
    if (isOpened && selectedChat && isOwnLastMessage === false) {
      readMessages();
    }
  }, [isOwnLastMessage, isOpened, selectedChat]);

  const close = () => {
    closeModal();
  };
  const renderView = ({ ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={styles.view} {...props} />
  );

  return (
    <Modal show={isOpened} onHide={close} size="lg">
      <Modal.Title>{t('game.chat.title')}</Modal.Title>
      <div className={styles.chat}>
        <div className={styles.users}>
          {users.map(({ id, name }) => (
            <div key={id}>
              <button
                className={cn(
                  styles.userButton,
                  selectedChat === id && styles.active
                )}
                type="button"
                onClick={() => setSelectedChat(id)}
              >
                <span
                  className={styles.colorSquare}
                  style={{ backgroundColor: getTeamColorById(id) }}
                />
                {name}
                {unconsumedMessages[id] > 0 && (
                  <span className={styles.unconsumedMessages}>
                    {unconsumedMessages[id]}
                  </span>
                )}
              </button>
            </div>
          ))}
        </div>
        <div className={cn(styles.block, styles.messagesBlock)}>
          {selectedChat && (
            <div className={styles.chatInfo}>
              {t('game.chat.dialogTitle', { name: selectedChatName })}
            </div>
          )}
          <div className={styles.messages}>
            {selectedChat ? (
              <Scrollbars
                style={{ width: '100%' }}
                autoHeightMax={365}
                ref={scrollRef}
                renderView={renderView}
              >
                <div className={styles.messagesWrapper}>
                  {messages.map(({ id, author, from, text, is_readed }) => {
                    const isOwnMessage = from === gameData.team_id;
                    return (
                      <div
                        key={id}
                        className={cn(
                          styles.message,
                          isOwnMessage && styles.own
                        )}
                      >
                        <div className={styles.messageInner}>
                          <div
                            className={styles.author}
                            style={{ color: getTeamColorById(from) }}
                          >
                            {author}
                          </div>
                          <div className={styles.text}>{text}</div>
                          {isOwnMessage && is_readed && (
                            <div className={styles.readed}>
                              <CheckIcon />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Scrollbars>
            ) : (
              <div className={styles.empty}>
                <img src={chatEmpty} alt="Выберите команду, чтобы начать чат" />
                Выберите команду,
                <br />
                чтобы начать чат
              </div>
            )}
          </div>
          {selectedChat && (
            <ChatInput disabled={!selectedChat} onSubmit={sendMessage} />
          )}
        </div>
      </div>
    </Modal>
  );
});
