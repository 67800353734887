import React from 'react';
import PropTypes from 'prop-types';

import styles from './cross-achievement.module.css';
import * as images from './images';

const names = [
  'За широкую экспансию',
  'За интенсивное развитие',
  'За охрану природы',
  'За скаутские успехи',
  'За доминирование',
];

export const CrossAchievement = ({ id, teamLabel }) => {
  const name = names[id - 1];
  return (
    <div className={styles.achievement}>
      <img
        className={styles.img}
        src={images[`achievement${id}`]}
        alt={id}
        title={name}
      />
      <span className={styles.label}>{teamLabel}</span>
      <span className={styles.name}>{name}</span>
    </div>
  );
};

CrossAchievement.propTypes = {
  id: PropTypes.number.isRequired,
  teamLabel: PropTypes.string.isRequired,
};
