import React from 'react';

export const AddIcon = () => (
  <i>
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="13.5" r="13.5" fill="url(#paint0_linear)" />
      <path stroke="#495C31" d="M6 13.5h15M13.5 6v15" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="13.5"
          y1="0"
          x2="13.5"
          y2="27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#519157" />
          <stop offset="1" stopColor="#6FA074" />
        </linearGradient>
      </defs>
    </svg>
  </i>
);
