/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './action-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import { CommandWithOption } from '../../components/command/command-with-option/command-with-option';
import { CommandWithInput } from '../../components/command/command-with-input/command-with-input';
import { CommandWithSelectAndInput } from '../../components/command/command-with-select-and-input/command-with-select-and-input';
import { AddedCommand } from '../../components/command/added-command/added-command';
import { LEVEL_DEFENSE, LEVEL_TYPES } from '../../constants';
import { Spinner } from '../../components/spinner/spinner';
import { LevelImage } from '../../components/level-image/level-image';
import { UpgradeIcon } from '../../icons/upgrade/upgrade';
import { DefenseIcon } from '../../icons/defense/defense';
import { CaptureIcon } from '../../icons/capture/capture';
import { TransferIcon } from '../../icons/transfer/transfer';
import { InvestIcon } from '../../icons/invest/invest';

const iconsByType = {
  upgrade: UpgradeIcon,
  defense: DefenseIcon,
  capture: CaptureIcon,
  transfer: TransferIcon,
  invest: InvestIcon,
};
const getIconByType = type => {
  return iconsByType[type];
};

const getAllNodes = (nodes, teamId, selectedNodeId) =>
  nodes
    .filter(item => item.owner === teamId && item.id !== selectedNodeId)
    .map(node => ({
      label: node.name,
      value: node.id,
      owner: node.owner,
      money: LEVEL_DEFENSE[node.level],
      canBeCaptured: node.canBeCaptured,
    }));

export const ActionModal = observer(() => {
  const { t } = useTranslation();
  const {
    showModal,
    closeActionModal,
    selectedNode,
    selectedNodeId,
    nodes,
    addAction,
    removeAction,
    commands,
    getActions,
    fetching,
    isUpgradeCommandAdded,
    isInvestCommandAdded,
    isDefenseCommandAdded,
    transferedCountries,
    capturedCountries,
    getTeamColorById,
    canCapture: _canCapture,
    canDefense: _canDefense,
    canInvesting: _canInvesting,
    canTransfer: _canTransfer,
    canUpgrade: _canUpgrade,
    canTransferWithoutRoutes,
    teamId,
  } = useAppStore();
  const isOpen = showModal === 'action';
  const { name, money, routes, level, owner } = selectedNode;
  const routesOptions = routes.map(route => {
    const node = nodes.find(({ id }) => id === route) || {};
    return {
      label: node.name,
      value: route,
      owner: node.owner,
      money: LEVEL_DEFENSE[node.level],
      canBeCaptured: node.canBeCaptured,
    };
  });
  const captureRoutesOptions = routesOptions.filter(
    item =>
      item.owner !== owner &&
      item.canBeCaptured &&
      !capturedCountries.includes(item.value)
  );
  const transferRoutesOptions = (canTransferWithoutRoutes
    ? getAllNodes(nodes, teamId, selectedNodeId)
    : routesOptions
  ).filter(
    item => item.owner === owner && !transferedCountries.includes(item.value)
  );
  const upgradeOption = LEVEL_TYPES[level];
  const expenses = commands.reduce((acc, item) => acc + Number(item.value), 0);
  const availableMoney = money - expenses;
  const canUpgrade = _canUpgrade && upgradeOption && !isUpgradeCommandAdded;
  const canDefense = _canDefense && !isDefenseCommandAdded;
  const canCapture = _canCapture && captureRoutesOptions.length > 0;
  const canTransfer = _canTransfer && transferRoutesOptions.length > 0;
  const canInvesting = _canInvesting && !isInvestCommandAdded;
  const hasAvailableCommands =
    canUpgrade || canInvesting || canTransfer || canDefense || canCapture;

  useEffect(() => {
    if (isOpen) {
      getActions();
    }
  }, [isOpen]);
  return (
    <Modal
      show={isOpen}
      onHide={closeActionModal}
      dialogClassName={styles.modal}
      closable={!fetching}
    >
      {isOpen && fetching && <Spinner />}
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <LevelImage level={level} />
          <div className={styles.nodeInfo}>
            <div className={styles.title}>{name}</div>
            <div className={styles.status}>
              <strong>{t(`game.levels.${level}`)}</strong>
            </div>
          </div>
        </div>
        <div className={styles.headerRight}>
          <div className={styles.money}>{availableMoney}</div>
          <div className={styles.expenses}>
            {t('action.expenses')}: {expenses}
          </div>
        </div>
      </div>
      <div className={styles.subtitle}>{t('action.neighbor')}</div>
      <div className={styles.routes}>
        {routes.map(route => {
          const node = nodes.find(({ id }) => id === route) || {};
          return (
            <span key={route} className={styles.route}>
              <span
                className={cn(
                  styles.routeLevel,
                  node.level === 0 && styles.empty,
                  node.isControl && styles.control
                )}
                style={{
                  backgroundColor: getTeamColorById(node.owner),
                  currentColor: getTeamColorById(node.owner),
                  // color: getStrokeColorByColor(TEAM_COLORS[node.owner]),
                }}
              >
                {node.level > 0 ? node.level : null}
              </span>
              <span
                className={cn(styles.routeName, node.level > 0 && styles.white)}
              >
                {node.name}
              </span>
            </span>
          );
        })}
      </div>
      {hasAvailableCommands && (
        <div className={styles.subtitle}>{t('action.availableActions')}</div>
      )}
      <div>
        {canUpgrade && (
          <CommandWithOption
            Icon={UpgradeIcon}
            availableMoney={availableMoney}
            label={t('action.upgrade')}
            option={upgradeOption}
            onAdd={(value, label) =>
              addAction('upgrade', { targetId: selectedNodeId, value, label })
            }
          />
        )}
        {canDefense && (
          <CommandWithInput
            Icon={DefenseIcon}
            availableMoney={availableMoney}
            label={t('action.defense')}
            onAdd={(value, label) =>
              addAction('defense', { targetId: selectedNodeId, value, label })
            }
          />
        )}
        {canCapture && (
          <CommandWithSelectAndInput
            key={`capture-${captureRoutesOptions.length}`}
            Icon={CaptureIcon}
            availableMoney={availableMoney}
            label={t('action.capture')}
            options={captureRoutesOptions}
            onAdd={(value, targetId, label) =>
              addAction('capture', { targetId, value, label })
            }
            withMinMoney
          />
        )}
        {canTransfer > 0 && (
          <CommandWithSelectAndInput
            key={`transfer-${transferRoutesOptions.length}`}
            Icon={TransferIcon}
            availableMoney={availableMoney}
            label={t('action.transfer')}
            options={transferRoutesOptions}
            onAdd={(value, targetId, label) =>
              addAction('transfer', { targetId, value, label })
            }
          />
        )}
        {canInvesting && (
          <CommandWithInput
            Icon={InvestIcon}
            availableMoney={availableMoney}
            label={t('action.invest')}
            onAdd={(value, label) =>
              addAction('invest', { targetId: selectedNodeId, value, label })
            }
          />
        )}
      </div>
      {commands.length > 0 && (
        <>
          <div className={styles.subtitle}>{t('action.selectedActions')}</div>
          <div className={styles.addedActions}>
            {commands.map(item => (
              <AddedCommand
                key={item.id}
                Icon={getIconByType(item.type)}
                id={item.id}
                onRemove={id => removeAction(id)}
                {...item}
              />
            ))}
          </div>
        </>
      )}
    </Modal>
  );
});
