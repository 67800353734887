import React from 'react';
import PropTypes from 'prop-types';

import styles from './level-image.module.css';

import bg1 from '../../assets/node-level-1.png';
import bg2 from '../../assets/node-level-2.png';
import bg3 from '../../assets/node-level-3.png';
import bg4 from '../../assets/node-level-4.png';
import bg5 from '../../assets/node-level-5.png';

const nodeIcon = [bg1, bg2, bg3, bg4, bg5];

export const LevelImage = ({ level }) => {
  return (
    <img
      className={styles.img}
      src={nodeIcon[level - 1]}
      alt={`level ${level}`}
    />
  );
};

LevelImage.propTypes = {
  level: PropTypes.number.isRequired,
};
