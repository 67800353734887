import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';

const initialValues = { name: '' };

function AuthForm({ submit }) {
  const validate = ({ name }) => {
    const errors = {
      name: name ? undefined : 'Имя обязательно',
    };
    return JSON.parse(JSON.stringify(errors));
  };
  const submitForm = async ({ name }) => {
    await submit(name);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validate={validate}
      >
        {({ handleSubmit, isSubmitting, handleChange, errors, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                  autoComplete="name"
                  placeholder="Введите имя"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                block
              >
                Войти
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

AuthForm.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default observer(AuthForm);
