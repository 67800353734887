import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './menu.module.css';
import { useAppStore } from '../../store/app';
import { MenuButton } from '../../components/menu-button/menu-button';
import { TEAM_COLORS } from '../../constants';

const menuConfig = [
  {
    modal: 'chat',
    showUnconsumedMessages: true,
  },
  {
    modal: 'help',
  },
];

export const Menu = observer(() => {
  const { openModal, gameData, chatStore, areNewsAvailable } = useAppStore();
  const { unconsumedMessagesCount } = chatStore;
  const { t } = useTranslation();
  const menu = [
    {
      backgroundColor: TEAM_COLORS[gameData.team_id],
      modal: 'stats',
    },
    ...(areNewsAvailable
      ? [
          {
            modal: 'news',
          },
        ]
      : []),
    ...menuConfig,
  ];
  return (
    <ul className={styles.menu}>
      {menu.map(({ modal, color, backgroundColor, showUnconsumedMessages }) => (
        <li key={modal}>
          <MenuButton
            onClick={() => openModal(modal)}
            color={color}
            backgroundColor={backgroundColor}
          >
            {t(`game.menu.${modal}`)}
            {showUnconsumedMessages && unconsumedMessagesCount > 0 && (
              <span className={styles.unconsumedMessagesCount}>
                {unconsumedMessagesCount}
              </span>
            )}
          </MenuButton>
        </li>
      ))}
    </ul>
  );
});
