import React from 'react';

export const CloseIcon = () => (
  <i>
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16" stroke="#fff" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0103 24.2126L9.78742 10.9897l1.20208-1.2021 13.2229 13.2229-1.2021 1.2021z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2125 10.9897L10.9896 24.2126l-1.20212-1.2021L23.0104 9.7876l1.2021 1.2021z"
        fill="#fff"
      />
    </svg>
  </i>
);
