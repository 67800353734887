import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Table } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import styles from './results.module.css';
import { useAppStore } from '../../store/app';

export const ResultsFull = observer(({ data, highlightTop = false }) => {
  const { getTeamNameById, getTeamColorById } = useAppStore();
  return (
    <div className={cn(styles.container)}>
      <Table className={cn(styles.table, highlightTop && styles.highlightTop)}>
        <thead>
          <th colSpan={2}>Название команды</th>
          <th className={styles.value}>Очки</th>
        </thead>
        <tbody>
          {data.map(({ id, value }) => (
            <tr key={id}>
              <td className={styles.colorCol}>
                <div
                  className={styles.colorSquare}
                  style={{ backgroundColor: getTeamColorById(id) }}
                />
              </td>
              <td className={styles.name}>{getTeamNameById(id)}</td>
              <td className={styles.value}>{value}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
});

ResultsFull.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  highlightTop: PropTypes.bool,
};
