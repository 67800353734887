export const TEST_TOKEN = 'test';
export const TEST_TOKEN_GUEST = 'guest_test';

export const API_STATES = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const APP_STATES = {
  INITIAL: 'initial',
  CHECKED: 'checked',
  SIGNED_IN: 'signed-in',
  UNAUTHORIZED: 'unauthorized',
  ERROR: 'error',
};

export const QUESTION_TYPES = {
  STUB: 0,
  QUESTION: 1,
  QUESTION_OPTIONS: 2,
  QUESTION_OPTIONS_RATE: 3,
  QUESTION_OPTIONS_INTERACTIVE: 4,
};

export const TEAM_COLORS = {
  1: '#0000FF',
  2: '#00FF00',
  3: '#F19324',
  4: '#FCEE21',
  5: '#FF0000',
  6: '#00FFFF',
  7: '#009245',
  8: '#FF00FF',
  9: '#A800FF',
  10: '#B45F08',
  11: '#999999',
};

export const TEAM_COLORS_LABELS = {
  1: 'Синий',
  2: 'Салатовый',
  3: 'Оранжевый',
  4: 'Желтый',
  5: 'Красный',
  6: 'Голубой',
  7: 'Зеленый',
  8: 'Розовый',
  9: 'Фиолетовый',
  10: 'Коричневый',
  11: 'Темно-серый',
};

export const TEAM_NAMES = {
  1: 'Россия',
  2: 'Сингапур',
  3: 'Дания',
  4: 'Украина',
  5: 'Китай',
  6: 'Бразилия',
  7: 'Беларусь',
  8: 'Казахстан',
  9: 'Польша',
  10: 'Армения',
  11: 'Нейтральные страны',
};

export const getStrokeColorByColor = color => {
  switch (color) {
    case TEAM_COLORS[1]:
    case TEAM_COLORS[7]:
    case TEAM_COLORS[9]:
    case TEAM_COLORS[10]:
      return '#FFF';
    default:
      return '#000';
  }
};

export const LEVEL_DEFENSE = {
  1: 50,
  2: 150,
  3: 300,
  4: 500,
  5: 800,
};

export const LEVEL_TYPES = [
  {
    level: 1,
    value: null,
    defense: LEVEL_DEFENSE[1],
  },
  {
    level: 2,
    value: 100,
    defense: LEVEL_DEFENSE[2],
  },
  {
    level: 3,
    value: 200,
    defense: LEVEL_DEFENSE[3],
  },
  {
    level: 4,
    value: 500,
    defense: LEVEL_DEFENSE[4],
  },
  {
    level: 5,
    value: 1000,
    defense: LEVEL_DEFENSE[5],
  },
];
