import React from 'react';

import styles from './clouds.module.css';

export const Clouds = () => (
  <>
    <div className={styles.dark} />
    <div className={styles.clouds} />
    <div className={styles.cloudsBottom} />
  </>
);
