import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import styles from './month.module.css';
import { getMonthName } from '../../helpers';
import { Round } from '../../icons/round/round';
import { useAppStore } from '../../store/app';

export const Month = observer(({ round }) => {
  const { teamColor } = useAppStore();
  const monthName = getMonthName(round);
  return (
    <div className={styles.month}>
      <div className={styles.bg}>
        <Round color={teamColor} />
      </div>
      <div className={styles.monthName} style={{ color: teamColor }}>
        {monthName}
      </div>
      <div className={styles.round}>
        <div className={styles.roundValue}>{round}</div>
        Раунд
      </div>
    </div>
  );
});

Month.propTypes = {
  round: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
};
