import axios from 'axios';

class ApiService {
  transport;

  constructor(baseURL) {
    this.transport = axios.create({
      baseURL,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  game = async () => this.transport.get('/game');

  connect = (token, name) =>
    this.transport.get('/connect', {
      params: {
        token,
        name,
      },
    });

  check = token =>
    this.transport.get('/check', {
      params: {
        token,
      },
    });

  actions = (token, nodeId) =>
    this.transport.get('/actions', {
      params: {
        token,
        node_id: nodeId,
      },
    });

  addAction = (token, type, nodeId, targetId, value, label) =>
    this.transport.get('/action', {
      params: {
        token,
        type,
        node_id: nodeId,
        target_id: targetId,
        value,
        label,
      },
    });

  removeAction = (token, id) =>
    this.transport.get('/remove_action', {
      params: {
        token,
        id,
      },
    });

  news = token =>
    this.transport.get('/news', {
      params: {
        token,
      },
    });

  stat = token =>
    this.transport.get('/stat', {
      params: {
        token,
      },
    });

  results = token =>
    this.transport.get('/results', {
      params: {
        token,
      },
    });

  /** CHAT */

  messages = (token, teamId) =>
    this.transport.get('/messages', {
      params: {
        token,
        team_id: teamId,
      },
    });

  read = (token, teamId) =>
    this.transport.get('/read', {
      params: {
        token,
        team_id: teamId,
      },
    });

  sendMessage = (token, teamId, text, author) =>
    this.transport.get('/send_message', {
      params: {
        token,
        team_id: teamId,
        text,
        author,
      },
    });
}

export default ApiService;
