import React, { useMemo, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { configure } from 'mobx';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AppStoreContext, AppStore } from './store/app';
import MainPage from './pages/MainPage/main-page';

configure({ enforceActions: 'observed' });

const parsed = queryString.parse(window.location.search);
const { token, showCoordinates } = parsed;

function App() {
  const config = {
    token,
    showCoordinates: Boolean(showCoordinates),
  };
  const store = useMemo(() => new AppStore(config), []);
  const { t } = useTranslation();

  useEffect(() => {
    store.start();
    return () => {
      store.cleanUp();
    };
  }, [store]);
  return (
    <AppStoreContext.Provider value={store}>
      <>
        <Helmet>
          <title>{t('game.name')}</title>
        </Helmet>
        <MainPage />
      </>
    </AppStoreContext.Provider>
  );
}

export default hot(App);
