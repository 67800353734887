import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';
import styles from './welcome-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import { Logo } from '../../icons/logo/logo';
import line from '../../assets/line-small.svg';

export const WelcomeModal = observer(() => {
  const {
    showModal,
    closeModal,
    teamColor,
    teamColorName,
    teamName,
  } = useAppStore();
  const { t } = useTranslation();
  return (
    <Modal
      show={showModal === 'welcome'}
      onHide={closeModal}
      size="lg"
      closable={false}
      dialogClassName={styles.modal}
    >
      <div className={styles.modalContainer}>
        <div className={styles.logo}>
          <Logo color="#FFFFFF" secondColor="#D93D2C" />
        </div>
        <div className={styles.gameName}>{t('game.name')}</div>
        <div className={styles.gameDescr}>
          Командная тактическая
          <br />
          online-игра <img src={line} alt="game" />
        </div>
        <div className={styles.title}>
          Приветствуем вас в командной стратегической online-игре
        </div>
        <div className={styles.description}>
          Цвет вашей команды на игровом поле —{' '}
          <span
            className={styles.teamColor}
            style={{
              backgroundColor: teamColor,
              // color: getStrokeColorByColor(TEAM_COLORS[team_id]),
            }}
          >
            {teamColorName}
          </span>
          <br />
          Вы играете за команду <strong>{teamName}</strong>
          <br />
          <br />
          Ваша задача - набрать как можно больше очков, чтобы стать лучшей
          командой скаутов! Для этого вы можете:
          <ul className={styles.list}>
            <li>захватывать новые точки</li>
            <li>развивать лагеря на захваченных точках</li>
            <li>получать значки за успехи</li>
            <li>бороться за переходящие призы</li>
          </ul>
        </div>
        <Button className={styles.button} onClick={closeModal}>
          Начать игру
        </Button>
      </div>
    </Modal>
  );
});
