import React from 'react';
import PropTypes from 'prop-types';

export const Round = ({ color = '#225227' }) => (
  <i>
    <svg
      width="80"
      height="101"
      viewBox="0 0 80 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00012 6.4919v74.5329c0 2.233 1.36236 4.2447 3.45673 5.0896L37.9058 99.5926c1.342.5434 2.8467.5434 4.1887 0l33.4489-13.4782c2.0944-.8449 3.4567-2.8566 3.4567-5.0896V6.4919C79.0001 3.45425 76.5194 1 73.449 1H6.55122c-3.07039 0-5.5511 2.45425-5.5511 5.4919z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M5.00012 25.9019V78.061c0 1.9947 1.24677 3.7984 3.14972 4.5411L38.5972 94.6339c1.2249.4881 2.6029.4881 3.8059 0l30.4473-12.0318c1.903-.7427 3.1497-2.5464 3.1497-4.5411V25.9019c0-2.7162-2.2748-4.9019-5.0527-4.9019H10.0528c-2.77788 0-5.05268 2.1857-5.05268 4.9019z"
        fill={color}
      />
    </svg>
  </i>
);

Round.propTypes = {
  color: PropTypes.string.isRequired,
};
