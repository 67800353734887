import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AuthForm from '../../components/auth-form/auth-form';
import RestoreSession from '../../components/restore-session/restore-session';
import Card from '../../components/card/card';
import classes from './auth.module.css';
import { useAppStore } from '../../store/app';
import { Logo } from '../../icons/logo/logo';
import line from '../../assets/line-small.svg';

function Auth() {
  const { connect, restoreSession, savedSession } = useAppStore();
  const { t } = useTranslation();
  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col>
          <Card className={classes.card} body>
            <div className={classes.logo}>
              <Logo color="#FFFFFF" secondColor="#D93D2C" />
            </div>
            <div className={classes.gameName}>{t('game.name')}</div>
            <div className={classes.gameDescr}>
              Командная тактическая
              <br />
              online-игра <img src={line} alt="game" />
            </div>
            <div className={classes.greetengs}>Добро пожаловать!</div>
            <div className={classes.text}>
              Введите свое имя, чтобы присоединиться к игре
            </div>
            <AuthForm submit={connect} />
            {savedSession && (
              <RestoreSession userName={savedSession} submit={restoreSession} />
            )}
            <div className={classes.mobileDisclamer}>
              Для удобства использования поверните устройство горизонтально
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Auth;
